(function ($) {

    function real_hotels_advantages(){
        if(is_mobile && ($('html').hasClass('homepage') || $('html').hasClass('apartments-page'))){
            $('.list-advantages-services').not('.slick-initialized').slick({
                rows: 0,
                slidesToShow:3,
                slidesToScroll:1,
                centerMode: true,
                centerPadding: 0,
                arrows: false
            });
        }
    }

    function real_hotels_filter_rooms(){
        if($('html').hasClass('rooms-page')){
            var container = $('.all-rooms');
            container.isotope({
                itemSelector: '.single-child',
                percentPosition: true,
                layoutMode: 'fitRowsCentered',
                fitRowsCentered: {
                    columnWidth: '.single-child',
                }
            });

            $('.filter select').change(function () {
                $('.single-child').removeAttr('data-aos');
                if(this.value === "all"){
                    container.isotope({filter: '*'});
                } else{
                    container.isotope({filter: this.value});
                }
            });
        }
    }

    function real_hotels_room_occupancy(){
        if(is_mobile && $('html').hasClass('room-page')){
            var room_occupancy = $('.room-occupancy .container');
            room_occupancy.not('slick-initialized').slick({
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                customPaging : function(slider, i) {
                    return '<a href="javascript:;"></a>';
                },
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }

    function real_hotels_filter_offers(){
        if($('html').hasClass('special-offers-page')){
            var container = $('.offers-section .container-small'),
                checkboxes = $('.filters input');
            container.isotope({
                layoutMode: 'fitRows',
                itemSelector: '.single-offer',
                percentPosition: true,
                fitRows: {
                    columnWidth: '.grid-sizer'
                }
            });

            checkboxes.change(function(){
                $('.single-offer').removeAttr('data-aos');
                var filters = [];

                checkboxes.filter(':checked').each(function(){
                    filters.push( this.value );
                });

                filters = filters.join(', ');

                container.isotope({ filter: filters });
            });

            if(is_mobile){
                var filter_mobile = $('.mobile-filter-offers select');
                filter_mobile.on('change', function(){
                    var value = $(this).val();
                    if(value === 'all'){
                        var filter = '*';
                    } else {
                        var filter = value;
                    }
                    container.isotope({ filter: filter });
                });
            }
        }
    }

    function real_hotels_menu_section(){
        if($('html').hasClass('restaurant-page')){
            var menu_section_carousel = $('.menu-carousel'),
            children = menu_section_carousel.children(),
            children_items = menu_section_carousel.find('.single-menu'),
            hasCarousel = false,
            totalWidth = 0;

            children_items.each(function(){
                totalWidth += $(this).outerWidth(true);
            });

            var checkWidth = totalWidth > menu_section_carousel.width();

            if(menu_section_carousel.length > 0){
                if(is_mobile && children.length > 1){
                    hasCarousel = true;
                } else{
                    if(checkWidth){
                        hasCarousel = true;
                    }
                }
                if(hasCarousel){
                    menu_section_carousel.on('init', function(event, slick){
                        lazyLoadInstance.update();
                    });
                    menu_section_carousel.not('.slick-initialized').slick({
                        rows: 0,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                        slide: '.single-menu',
                        customPaging : function(slider, i) {
                            return '<a href="javascript:;"></a>';
                        },
                        responsive:[
                            {
                                breakpoint: 1366,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                }
                            },
                            ]
                    });
                }
            }
        }
    }

    function real_hotels_events_restaurant(){
        if($('html').hasClass('restaurant-page')){
            var events_carousel = $('.events-restaurant-carousel'),
            events = events_carousel.children(),
            hasCarousel = false;

            if(events_carousel.length > 0){
                if(is_mobile && events.length > 1){
                    hasCarousel = true
                } else{
                    if(events.length > 3){
                        hasCarousel = true
                    }
                }
                if(hasCarousel){
                    events_carousel.on('init', function(event, slick){
                        lazyLoadInstance.update();
                    });
                    events_carousel.not('.slick-initialized').slick({
                        rows: 0,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
                        nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
                        responsive:[
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    });
                }
            }
        }
    }

    function real_hotels_apartments_discover_more(){
        var apartments_section = $('.apartments-section'),
        apartment = apartments_section.find('.single-apartment');
        if($('html').hasClass('apartments-page') && apartments_section.length > 0){
            apartment.each(function(){
                var item = $(this),
                discover_more = item.find('.read-more'),
                content_height = item.find('.apartment-text').outerHeight(true);
                if(discover_more.length > 0){
                    discover_more.on('click', function(){
                        var siblings = item.siblings('.single-apartment'),
                        siblings_read_more = siblings.find('.read-more');
                        if(!discover_more.hasClass('open')){
                            discover_more.addClass('open');
                            item.find('.apartment-text-wrap').css({
                                height: content_height
                            });
                            discover_more.text(discover_more.data('opentext'));
                        } else{
                            discover_more.removeClass('open');
                            item.find('.apartment-text-wrap').css({
                                height: 0
                            });
                            discover_more.text(discover_more.data('closetext'));
                        }

                        if(siblings_read_more.hasClass('open')){
                            siblings.find('.apartment-text-wrap').css({
                                height: 0
                            });
                            siblings_read_more.removeClass('open')
                            siblings_read_more.text(siblings_read_more.data('closetext'));
                        }
                    });
                }
            });
        }
    }

    function real_hotels_faq_manager(){
        $('.faq').on('click', function(){
            var opened_class = 'opened',
            siblings = $(this).siblings();
            if(!$(this).hasClass(opened_class)){
                $(this).addClass(opened_class);
            } else {
                $(this).removeClass(opened_class);
            }

            if(siblings.hasClass(opened_class)){
                siblings.removeClass(opened_class)
            }
        });
    }

    function real_hotels_filter_treatments(){
        if($('html').hasClass('treatments-page')){
            //filters items
            var treatments_container = $('.all-treatments');

            treatments_container.isotope({
                itemSelector: '.single-treatment',
                percentPosition: true,
                masonry: {
                    columnWidth: '.single-treatment'
                }
            });

            $('.filter select').change(function () {
                $('.single-treatment').removeAttr('data-aos');
                if(this.value === "all"){
                    treatments_container.isotope({filter: '*'});
                } else{
                    treatments_container.isotope({filter: this.value});
                }
            });
        }
    }

    function real_hotels_treatments_manager(){
        if($('html').hasClass('treatments-page') || $('html').hasClass('post-type-archive-post_treatment')){
            //single treatment discover more
            if($('.all-treatments').length > 0){
                var single_treatment = $('.single-treatment');
                single_treatment.each(function(){
                    var item = $(this),
                        discover_more = item.find('.read-more'),
                        extra_content_height = item.find('.extra-content').outerHeight(true);
                    if(discover_more.length > 0){
                        discover_more.on('click', function(){
                            var siblings = item.siblings('.single-treatment'),
                                siblings_read_more = siblings.find('.read-more');
                            if(!discover_more.hasClass('open')){
                                discover_more.addClass('open');
                                item.find('.extra-content-wrap').css({
                                    height: extra_content_height
                                });
                                discover_more.text(discover_more.data('opentext'));

                                if(!$('html').hasClass('post-type-archive-post_treatment')){
                                    setTimeout( function() {
                                        $('.all-treatments').isotope('layout');
                                    }, 550 );
                                }

                            } else{
                                discover_more.removeClass('open');
                                item.find('.extra-content-wrap').css({
                                    height: 0
                                });
                                discover_more.text(discover_more.data('closetext'));

                                if(!$('html').hasClass('post-type-archive-post_treatment')){
                                    setTimeout( function() {
                                        $('.all-treatments').isotope('layout');
                                    }, 550 );
                                }
                            }

                            if(siblings_read_more.hasClass('open')){
                                siblings.find('.extra-content-wrap').css({
                                    height: 0
                                });
                                siblings_read_more.removeClass('open')
                                siblings_read_more.text(siblings_read_more.data('closetext'));

                                setTimeout( function() {
                                    $('.all-treatments').isotope('layout');
                                }, 550 );
                            }
                        });
                    }
                });
            }
        }
    }

    function real_hotels_photogallery(){
        if($('html').hasClass('photogallery-page')){
            var photogallery_section = $('.photogallery-section'),
            single_photogallery_carousel = $('.photogallery-section .photogallery-carousel');
            if(photogallery_section.length > 0){
                single_photogallery_carousel.on('init', function(event, slick){
                    lazyLoadInstance.update();
                });
                single_photogallery_carousel.not('.slick-initialized').slick({
                    rows:0,
                    slidesToShow:1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '0',
                    variableWidth: true,
                    prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                    responsive:[
                        {
                            breakpoint: 991,
                            settings: {
                                variableWidth: false,
                                infinite: false
                            }
                        }
                    ]
                });
            }
        }
    }

    function real_hotels_meeting_capacities(){
        if($('html').hasClass('meetings-capacities-page')){

            //single treatment discover more
            if($('.all-meetings').length > 0){
                var single_meeting = $('.single-meeting');
                single_meeting.each(function(){
                    var item = $(this),
                        discover_more = item.find('.read-more'),
                        extra_content_height = item.find('.meeting-content').outerHeight(true);
                    if(discover_more.length > 0){
                        discover_more.on('click', function(){
                            var siblings = item.siblings('.single-meeting'),
                                siblings_read_more = siblings.find('.read-more');
                            if(!discover_more.hasClass('open')){
                                discover_more.addClass('open');
                                item.find('.meeting-content-wrap').css({
                                    height: extra_content_height
                                });
                                discover_more.text(discover_more.data('opentext'));
                            } else{
                                discover_more.removeClass('open');
                                item.find('.meeting-content-wrap').css({
                                    height: 0
                                });
                                discover_more.text(discover_more.data('closetext'));
                            }

                            if(siblings_read_more.hasClass('open')){
                                siblings.find('.meeting-content-wrap').css({
                                    height: 0
                                });
                                siblings_read_more.removeClass('open')
                                siblings_read_more.text(siblings_read_more.data('closetext'));
                            }
                        });
                    }
                });
            }
        }
    }

    function real_hotels_request_proposal_form(){
        if (typeof gformInitDatepicker !== 'undefined') {
            gformInitDatepicker();
        }
        //Meeting request form
        if($('.gform_wrapper').hasClass('meeting-request-form_wrapper')){
            if($('.general_info').length > 0){
                $('.general-info-title').wrapAll("<div class='general-info-title-content'></div>");
                $('.general_info').wrapAll("<div class='general-info-content'></div>");
                $('.contacts-info').wrapAll("<div class='contacts-info-content'></div>");
                $('.general-info-title-content, .general-info-content, .contacts-info-content').wrapAll("<div class='general-info-wrapper'></div>");
            }
            if($('.event-info').length > 0){
                $('.event-info').wrapAll("<div class='event-info-wrapper'></div>");
            }
            if($('.meeting-info').length > 0){
                $('.meeting-info').wrapAll("<div class='meeting-info-wrapper'></div>");
            }
            if($('.extra-services').length > 0){
                $('.extra-services').wrapAll("<div class='extra-services-wrapper'></div>");
            }
            if($('.privacy-info').length > 0){
                $('.privacy-info').wrapAll("<div class='privacy-info-wrapper'></div>");
            }
            if($('.required-fields').length > 0){
                $('.required-fields').wrapAll("<div class='required-fields-wrapper'></div>");
            }
        }

        //Wedding request form
        if($('.gform_wrapper').hasClass('wedding-request-form_wrapper')){
            if($('.general-info').length > 0){
                $('.general-info').wrapAll("<div class='general-info-wrapper'></div>");
            }

            if($('.wedding-info').length > 0){
                $('.wedding-info').wrapAll("<div class='wedding-info-wrapper'></div>");
            }

            if($('.ceremony-info').length > 0){
                $('.ceremony-info').wrapAll("<div class='ceremony-info-wrapper'></div>");
            }

            if($('.privacy-info').length > 0){
                $('.privacy-info').wrapAll("<div class='privacy-info-wrapper'></div>");
            }
            if($('.required-fields').length > 0){
                $('.required-fields').wrapAll("<div class='required-fields-wrapper'></div>");
            }
        }
        $(document).on('gform_page_loaded', function(event, form_id, current_page){
            if($('.gform_wrapper').hasClass('meeting-request-form_wrapper')){
                if($('.general_info').length > 0){
                    $('.general-info-title').wrapAll("<div class='general-info-title-content'></div>");
                    $('.general_info').wrapAll("<div class='general-info-content'></div>");
                    $('.contacts-info').wrapAll("<div class='contacts-info-content'></div>");
                    $('.general-info-title-content, .general-info-content, .contacts-info-content').wrapAll("<div class='general-info-wrapper'></div>");
                }
                if($('.event-info').length > 0){
                    $('.event-info').wrapAll("<div class='event-info-wrapper'></div>");
                }
                if($('.meeting-info').length > 0){
                    $('.meeting-info').wrapAll("<div class='meeting-info-wrapper'></div>");
                }
                if($('.extra-services').length > 0){
                    $('.extra-services').wrapAll("<div class='extra-services-wrapper'></div>");
                }
                if($('.privacy-info').length > 0){
                    $('.privacy-info').wrapAll("<div class='privacy-info-wrapper'></div>");
                }
                if($('.required-fields').length > 0){
                    $('.required-fields').wrapAll("<div class='required-fields-wrapper'></div>");
                }
            }

            if($('.gform_wrapper').hasClass('wedding-request-form_wrapper')){
                if($('.general-info').length > 0){
                    $('.general-info').wrapAll("<div class='general-info-wrapper'></div>");
                }

                if($('.wedding-info').length > 0){
                    $('.wedding-info').wrapAll("<div class='wedding-info-wrapper'></div>");
                }

                if($('.ceremony-info').length > 0){
                    $('.ceremony-info').wrapAll("<div class='ceremony-info-wrapper'></div>");
                }
                if($('.privacy-info').length > 0){
                    $('.privacy-info').wrapAll("<div class='privacy-info-wrapper'></div>");
                }
                if($('.required-fields').length > 0){
                    $('.required-fields').wrapAll("<div class='required-fields-wrapper'></div>");
                }
            }
        });
    }

    function real_hotels_filter_show(){
        if($('html').hasClass('shows-page')){
            var container = $('.offers-section .container-small'),
                checkboxes = $('.filters input');

            container.isotope({
                layoutMode: 'fitRows',
                itemSelector: '.single-offer',
                percentPosition: true,
                fitRows: {
                    columnWidth: '.grid-sizer'
                }
            });

            checkboxes.change(function(){
                $('.single-offer').removeAttr('data-aos');
                var filters = [];

                checkboxes.filter(':checked').each(function(){
                    filters.push( this.value );
                });

                filters = filters.join(', ');
                container.isotope({ filter: filters });
            });

            if(is_mobile){
                var filter_mobile = $('.mobile-filter-offers select');
                filter_mobile.on('change', function(){
                    var value = $(this).val();
                    if(value === 'all'){
                        var filter = '*';
                    } else{
                        var filter = value;
                    }
                    container.isotope({ filter: filter });
                });
            }
        }
    }

    function real_hotels_initializeMap(div){
        if (typeof(div) === "undefined") div = "map_canvas";
        if (typeof(directionsService) === "undefined") directionsService = new google.maps.DirectionsService();
        if (typeof(bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();
        directionsDisplay = new google.maps.DirectionsRenderer();

        var marker, i;

        var infoWindow = new google.maps.InfoWindow({
            'maxWidth': 500,
            'maxHeight': 400,
        });

        center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

        map = new google.maps.Map(document.getElementById(div), {
            // scroll wheel
            scrollwheel: false,
            //zoom
            zoom: map_config.map_zoom,
            zoomControl: map_config.map_zoom_control,
            zoomControlOptions: {
                position: google.maps.ControlPosition[map_config.zoom_control_position]
            },
            //position
            center: new google.maps.LatLng(center[0], center[1]),
            //map type
            mapTypeId: google.maps.MapTypeId[map_config.map_type],
            mapTypeControl: map_config.map_type_control,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
                style: google.maps.MapTypeControlStyle.DEFAULT
            },
            // default pan control
            panControl: false,
            panControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            // scale control - image with the scale index (m,km,etc)
            scaleControl: false,
            scaleControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            // 	streetview controls
            streetViewControl: map_config.map_streetview_control,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }

        });

        var styles = [];
        map.setOptions({styles: styles});

        for (i = 0; i < map_config.markers.length; i++) {

            if (i == 0) {
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(map_config.markers[0].latitude, map_config.markers[0].longitude),
                    map: map,
                    icon: map_config.markers[0].icon_hotel,
                    zIndex:99999999
                });

                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infoWindow.setContent(map_config.markers[i].html);
                        infoWindow.open(map, marker);
                    };
                })(marker, i));
                $(document).on('click', '.close', function(event){
                    infoWindow.close(map, marker);
                });
            }
        }
    }

    function real_hotels_calcRoute(div) {
        var start = document.getElementById('itineraryFrom').value;
        var end = '' + map_config.hotel_info.latitude + ',' + map_config.hotel_info.longitude;

        // remove previous message if present
        if ($('.itineraryPanelWrapper').length > 0) {
            $('.itineraryPanelWrapper').remove();
        }
        // delete previous results
        if (typeof directionsDisplay === 'undefined') {
            directionsDisplay = new google.maps.DirectionsRenderer();
        }
        //reset close button display
        $(".close_map.bottom").css("display", "inherit");

        $('#map_directions').fadeIn();

        directionsDisplay.setPanel(null);
        directionsDisplay.setMap(map);
        directionsDisplay.setPanel(document.getElementById('map_directions_content'));


        if (typeof start === 'undefined' || start == '') {
            start = $('input[name=itineraryFrom]').val();
        }

        if (start == '') {
            $('<div class="title">' + empty_message_html + '</div>').appendTo(jQuery('#map_directions_content'));
            directionsDisplay.setPanel(null);
            directionsDisplay.setMap(null);
            map.setZoom(map_config.map_zoom);
            $(".close_map.bottom").css("display", "none");

        } else {

            var request = {
                origin: start,
                destination: end,
                travelMode: google.maps.TravelMode[travel_mode]
            };

            directionsService.route(request, function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    $('<div class="title ok_messagge">' + ok_message_html + '</div>').appendTo($('#map_directions_content'));
                }
                else if (status == google.maps.DirectionsStatus.NOT_FOUND) {
                    $('<div class="title">' + not_found_message_html + '</div>').appendTo($('#map_directions_content'));
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    map.setZoom(map_config.map_zoom);
                    map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
                    $(".close_map.bottom").css("display", "none");
                }
                else {
                    $('<div class="title">' + err_message_html + '</div>').appendTo($('#map_directions_content'));
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    map.setZoom(map_config.map_zoom);
                    map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
                    $(".close_map.bottom").css("display", "none");
                }
            });
        }

        //this part handle the close_map button
        $('.close-map').click(function () {
            $('#map_directions').fadeOut(function () {
                directionsDisplay.setPanel(null);
                directionsDisplay.setMap(null);
                map.setZoom(map_config.map_zoom);
                map.setCenter(new google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
            });
            $('.itineraryPanelWrapper').remove();
        });

    }

    function real_hotels_handler_homepage(){
        if($('html').hasClass('home') || $('html').hasClass('apartments-page')){
            if (($('#map_canvas').length > 0)) {
                var __home_map_loaded = false;
                var position_map = $('#map_canvas').offset().top - $('#map_canvas').outerHeight();
                $(window).on("scroll", function() {
                    var scrollPosition = window.pageYOffset;
                    if(!__home_map_loaded && scrollPosition >= position_map){
                        __home_map_loaded = true;
                        if (($('#map_canvas').length > 0)) {
                            real_hotels_initializeMap();
                        }
                    }
                });
            }
        } else {
            if (($('#map_canvas').length > 0)) {
                real_hotels_initializeMap();
            }
        }
    }

    function real_hotels_awards_home(){
        if($('html').hasClass('homepage') || $('html').hasClass('apartments-page')) {
            var awards_carousel = $('.awards-carousel'),
                children = awards_carousel.children(),
                hasCarousel = false;
            if(awards_carousel.length > 0){
                if( is_mobile && children.length > 1){
                    hasCarousel = true;
                } else {
                    if(children.length > 3){
                        hasCarousel = true;
                    }
                }
                if(hasCarousel){
                    awards_carousel.on('init', function(event, slick){
                        lazyLoadInstance.update();
                    });
                    awards_carousel.slick({
                        rows: 0,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 3500,
                        speed: 3000,
                        arrows: false,
                        dots: true,
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        waitForAnimate: false,
                        prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                        nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                        customPaging : function(slider, i) {
                            return '<a href="javascript:;"></a>';
                        },
                        responsive:[
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 3,
                                    arrows: true,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    arrows: true,
                                    dots: false,
                                }
                            }
                        ]
                    });
                }
            }
        }
    }

    //Load Window
    $(window).on('load', function () {
        real_hotels_filter_rooms();
        real_hotels_filter_treatments();
        real_hotels_filter_offers();
    });

    //Resize Window
    $(window).resize(function () {

    });

    //Dom ready
    $(document).ready(function () {
        real_hotels_advantages();
        real_hotels_room_occupancy();
        real_hotels_menu_section();
        real_hotels_events_restaurant();
        real_hotels_apartments_discover_more();
        real_hotels_faq_manager();
        real_hotels_treatments_manager();
        real_hotels_photogallery();
        real_hotels_meeting_capacities();
        real_hotels_request_proposal_form();
        real_hotels_filter_show();
        real_hotels_handler_homepage();
        real_hotels_awards_home();
        if($('html').hasClass('home') || $('html').hasClass('apartments-page') || $('html').hasClass('location-page')){
            /*Manage itinerary*/
            window.travel_mode = 'DRIVING';
            $('.travel-mode a').on('click',function() {
                travel_mode = $(this).data('travel');
                $(this).addClass('active').siblings().removeClass('active');
            });
            $('#itinerarySubmit').on('click', function () {
                real_hotels_calcRoute('map_canvas');
            });
        }
    });
})(jQuery);